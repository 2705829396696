import React from 'react';
import styled from 'styled-components';

const StyledCopy = styled.div`
	padding: 3rem 0;
	text-align: center;
	width: 70%;
	margin: 0 auto;
	p {
		max-width: 800px;
		margin: 0 auto;
	}

	@media all and (min-width: 1600px) {
		p {
			max-width: 1000px;
			font-size: 1.3rem;
			line-height: 1.8rem;
		}
	}
`;

const Copy = ({ text }) => {
	return (
		<StyledCopy>
			<p>{text}</p>
		</StyledCopy>
	);
};

export default Copy;
