import React from 'react';

import Copy from '../components/copy';
import Masthead from '../components/masthead';
import RinksCTA from '../components/rinks-cta';
import GalleryCards from '../components/projects/gallery-cards';
import Bar from '../components/edgebar';

export default ({ projects, data }) => (
	<>
		<Masthead title={data.title} img={data.mastImg} />
		<Copy text={data.copy} />
		<GalleryCards projects={projects} />
		<Bar
			color="red"
			left="auto"
			right="0"
			top="-350px"
			width="auto"
			height="500px"
		/>
		<RinksCTA />
	</>
);
