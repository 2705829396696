import React from 'react';
import styled from 'styled-components';

const StyledProjectCards = styled.div`
	padding: 4rem;
	justify-content: center;
	background-image: url(/img/FLR_Ice.jpg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	.cards-container {
		max-width: 1200px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		.project-card {
			box-shadow: 3px 5px 21px rgba(25, 14, 10, 0.5);
			margin: 1rem;
			background-color: #fff;
			width: calc(50% - 2rem);
			display: flex;
			.left {
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				width: 50%;
			}
			.right {
				width: 50%;
				margin-left: auto;
				padding: 1.7rem 1rem 2.5rem;
				h2 {
					text-transform: uppercase;
					color: ${(p) => p.theme.color.red};
				}
				p {
					font-size: 0.9rem;
					line-height: 1.2rem;
					margin: 0.5rem 0 2rem;
				}
			}
		}
	}
	@media all and (min-width: 1600px) {
		.cards-container {
			max-width: 1350px;
			.project-card {
				margin: 1rem 2rem 2rem;
				width: calc(50% - 4rem);
			}
		}
		h2 {
			font-size: 2rem;
			line-height: 2.5rem;
		}
		p,
		a {
			font-size: 1.2rem !important;
			line-height: 1.4rem !important;
		}
	}
	@media all and (max-width: 900px) {
		padding: 3rem 0 4rem;
		.cards-container {
			flex-direction: column;
			.project-card {
				width: 70%;
				margin: 1rem auto;
			}
		}
	}
	@media all and (max-width: 668px) {
		.cards-container {
			.project-card {
				width: 80%;
			}
		}
	}
	@media all and (max-width: 450px) {
		padding: 2rem 0;
		.cards-container {
			.project-card {
				flex-direction: column;
				.left {
					width: 100%;
					height: 180px;
				}
				.right {
					width: 100%;
				}
			}
		}
	}
`;

const ProjectCards = ({ projects }) => {
	return (
		<StyledProjectCards>
			<div className="cards-container">
				{projects.map((item, index) => (
					<div key={index} className="project-card">
						<div
							className="left"
							style={{ backgroundImage: `url(${item.node.gallery.img})` }}
						/>
						<div className="right">
							<h2>{item.node.title}</h2>
							<p>{item.node.gallery.excerpt}</p>
							<a href={item.node.fields.slug} className="redButton">
								VIEW
							</a>
						</div>
					</div>
				))}
			</div>
		</StyledProjectCards>
	);
};

export default ProjectCards;
