import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import ProjectGalleryTemplate from '../templates/project-gallery-template';
import SEO from '../components/seo';

export const GalleryPageQuery = graphql`
	{
		projects: allProjectsJson(
			sort: { fields: [sortIndex, title], order: ASC }
		) {
			edges {
				node {
					title
					description
					gallery {
						img
						excerpt
					}
					fields {
						slug
					}
				}
			}
		}
	}
`;

const copy = {
	title: 'PROJECT GALLERY',
	mastImg: '/img/FLR_Project_Gallery.jpg',
	copy:
		'At First Line Rinks, we tackle every backyard skating rink with professionalism and integrity ensuring the highest quality work. Explore our recent projects here to see the professional touch we provide in all of our ice rink installations.',
};

const GalleryPage = ({ data }) => {
	return (
		<Layout>
			<SEO
				title="Projects"
				description="Take a look at some of our best backyard ice rinks, custom skating rinks, and synthetic rink solutions and see what our clients have to say."
			/>
			<ProjectGalleryTemplate data={copy} projects={data.projects.edges} />
		</Layout>
	);
};

export default GalleryPage;
